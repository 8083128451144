import config from "../Config";

function Logging() {

    function cleanArgs(obj) {
        let args = [];

        try {
            if (obj.callee && obj.callee.caller && obj.callee.caller.name)
                args = [obj.callee.caller.name];
        } catch { }

        for (let i = 0; i < obj.length; i++) {
            if (obj[i] instanceof Error) {
                args.push(obj[i]);
            } else if (typeof obj[i] === 'object' && obj[i] !== null) {
                args.push(obj[i]);
            } else {
                args.push(obj[i]);
            }
        }

        return args;
    }

    function objToString(obj, offset = 0) {
        let args = [];

        try {
            if (obj.callee && obj.callee.caller && obj.callee.caller.name)
                args = [obj.callee.caller.name];
        } catch { }

        for (let i = offset; i < obj.length; i++) {
            if (obj[i] instanceof Error) {
                let message = obj[i].message;
                let stack = obj[i].stack;
                // eslint-disable-next-line
                stack = stack.replace(/  /g, '').replace(/[\r\t]/g, '').replace(/\n/g, ', ');
                args.push(message);
                args.push(stack);
            } else if (typeof obj[i] === 'object' && obj[i] !== null) {
                args.push(JSON.stringify(obj[i]));
            } else {
                args.push(obj[i]);
            }
        }

        return args;
    }

    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    function appendLeadingZeroesTripple(n) {
        if (n <= 9) {
            return "00" + n;
        }
        if (n <= 99) {
            return "0" + n;
        }

        return n;
    }

    function now() {
        let current_datetime = new Date()
        let formatted_date = current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate()) + " " + appendLeadingZeroes(current_datetime.getHours()) + ":" + appendLeadingZeroes(current_datetime.getMinutes()) + ":" + appendLeadingZeroes(current_datetime.getSeconds()) + "." + appendLeadingZeroesTripple(current_datetime.getMilliseconds())

        return formatted_date;
    }

    function out(level, args) {
        args.unshift(level);
        console.log(args.join(' | '));
    }

    return {
        info() {
            let args = objToString(arguments);
            out("INFO", args);
        },
        alert() {
            let args = objToString(arguments);
            out("ALERT", args);
        },
        exception() {
            let args = objToString(arguments);
            out("EXCEPTION", args);
        },
        error() {
            let args = objToString(arguments);
            out("ERROR", args);
        },
        debug() {
            if (config.debug) {
                console.log("DEBUG", now(), cleanArgs(arguments));
            }
        }
    }
}
export default Logging;
