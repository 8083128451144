import React, { useEffect, useState } from 'react';
import { Grid, _ } from "gridjs-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";
import DatePicker from 'react-date-picker';
import moment from 'moment-timezone';
import 'moment/locale/da';

const Holidays = () => {
  const { info } = Log();

  const { httpHandler } = useAuthContext();

  const [disable, setDisable] = React.useState(false);

  const [edit, setEdit] = useState({
    id: 0,
    name: '',
    date: ''
  });

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    items: []
  });

  const addItem = async ({ setDisable, setData }) => {
    setDisable(true);

    const newItem = { ...edit };
    newItem.date.setHours(newItem.date.getHours() + 2);

    const addResult = await httpHandler('PUT', '/api/holiday', { item: newItem });
    if (addResult && !addResult.error) {
      await loadData({ setData });
      setDisable(false);
      setEdit({
        id: 0,
        name: '',
        date: ''
      });
      return true;
    }
    else if (addResult?.expired) {
      return false;
    }
    else
      setDisable(false);
  }
  const deleteItem = async ({ setData, id }) => {
    const deleteResult = await httpHandler('DEL', '/api/holiday?id=' + id);
    if (deleteResult && !deleteResult.error) {
      await loadData({ setData });
    }
  }
  const updateItem = async () => {
    const updateItem = { ...edit };
    updateItem.date.setHours(updateItem.date.getHours() + 2);

    const updateResult = await httpHandler('PATCH', '/api/holiday', { item: updateItem });

    if (updateResult && !updateResult.error) {
      setEdit({
        id: 0,
        name: '',
        date: ''
      });
      await loadData({ setData });
    }
  }

  const editItem = async ({ id }) => {
    const items = data.items.filter(d => d.id === id);
    if (items.length) {
      const editItem = {
        id,
        name: items[0].name,
        date: new Date(items[0].date)
      }

      setEdit({ ...editItem });
    }
  }

  const shouldDisable = () => {
    return disable || !edit.name || !edit.date;
  }

  const loadData = async ({ setData }) => {
    info(new Date(), 'start loading data from api');
    setLoading(true);
    const result = await httpHandler('GET', '/api/holiday');
    if (result && !result.error) {
      const items = result?.data?.holidays ?? [];

      info(new Date(), 'finished loading data from api');
      setData({
        items
      });

      setLoading(false);
    }
  }

  useEffect(() => {
    loadData({ setData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData])

  return (
    <>
      <Form className="container container-wide">
          <Row className="card card-header">
            <Col>
              <span className="header">
                Helligdage
              </span>
            </Col>
          </Row>
        {
          data.items?.length === 0 && (
            <Container>
              <Row className="card-body">
                <Col>
                  Henter data..
                </Col>
              </Row>
            </Container>
          )
        }
        {
          edit?.id === 0 && !loading && (
            <>
              <Container>
                <Row className="card-body">
                  <Col sm>
                    <Form.Control type="text" placeholder="Navn" value={edit.name} maxLength="150" onChange={e => setEdit({ ...edit, name: e.currentTarget.value })} />
                  </Col>
                  <Col sm>
                    <DatePicker value={edit.date} onChange={date => setEdit({ ...edit, date })} clearIcon={null} />
                  </Col>
                  <Col sm className="center last">
                    <Button variant="primary" disabled={shouldDisable()} onClick={() => addItem({ setDisable, setData })}>Tilføj ny helligdag</Button>
                  </Col>
                </Row>
              </Container>
              <Container className="container-wide">
                <Row className="card-body">
                  <Col sm>
                    <Grid
                      data={
                        data.items.map((d) => [d.name, moment(d.date).format('L'),
                        _(<Row className="last">
                          <Col className="col-auto">
                            <Button variant="warning" onClick={() => {
                              editItem({ id: d.id })
                            }}>Ret</Button>
                          </Col>
                          <Col className="col-auto">
                            <Button variant="danger" onClick={() => {
                              if (window.confirm('Er du sikker på at du vil slette denne helligdag?')) deleteItem({ setData, id: d.id })
                            }}>Slet</Button>
                          </Col>
                        </Row>
                        )
                        ])
                      }

                      columns={[
                        {
                          name: 'Navn',
                          sort: {
                            enabled: true
                          }
                        },
                        {
                          name: 'Dato',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          id: 'actions',                          
                          sort: {
                            enabled: false
                          }
                        }]}
                      sort={true}
                      pagination={{
                        enabled: true,
                        limit: 20
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )
        }
        {
          edit?.id !== 0 && (
            <Container className="edit">
              <Row className="card-body">
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Navn</Form.Label>
                  <Form.Control type="text" placeholder="Navn" required maxLength="50" value={edit.name} onChange={e => setEdit({ ...edit, name: e.currentTarget.value })} />
                  <Form.Control.Feedback type="invalid">
                    Navn skal angives
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group as={Col} md="2" controlId="validationCustom02">
                  <Form.Label>Dato</Form.Label>
                  <DatePicker value={edit.date} onChange={date => setEdit({ ...edit, date })} clearIcon={null} />
                  <Form.Control.Feedback type="invalid">
                    Dato skal angives
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group as={Col} md="2">
                  <Button variant="danger" className="col-sm" onClick={() => { setEdit({ id: 0, name: '', date: '' }) }}>Fortryd, retur til liste</Button>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Button variant="success" disabled={shouldDisable()} onClick={() => { updateItem(); }}>Gem</Button>
                </Form.Group>
              </Row>
            </Container>
          )
        }
      </Form>
    </>
  );

}
export default Holidays;