import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";

const Rotation = () => {
  const { debug } = Log();

  const { httpHandler } = useAuthContext();
  const [guardData, setGuardData] = useState({
    unSelectedGuards: [],
    dnd: {
      guardsInRotation: {
        name: "Rotation",
        items: []
      },
      guardsAppended: {
        name: "Tilføjes i slutningen af ringekæden",
        items: []
      }
    }
  });
  //debug('begin rotation', guardData?.dnd?.guardsInRotation?.items?.length)

  const onDragEnd = (result, guardData, setGuardData) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = guardData.dnd[source.droppableId];
      const destColumn = guardData.dnd[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      sourceItems.forEach(d => d.appended = source.droppableId === "guardsAppended");
      destItems.forEach(d => d.appended = destination.droppableId === "guardsAppended");

      refreshGuards({
        ...guardData,
        dnd: {
          ...guardData.dnd,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        }
      }, setGuardData, true);
    } else {
      const column = guardData.dnd[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      refreshGuards({
        ...guardData,
        dnd: {
          ...guardData.dnd,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        }
      }, setGuardData, true);
    }
  };
  const refreshGuards = (guardData, setGuardData, persist = false) => {
    setGuardData(guardData);

    if (persist) {

      const guards = [].concat(guardData.dnd.guardsInRotation.items).concat(guardData.dnd.guardsAppended.items);

      saveData(guards);
    }
  }
  const saveData = async (guards) => {
    await httpHandler("POST", '/api/rotation', { guards });
  }
  const buildData = ({ guardData, unSelectedGuards, guardsInRotation, guardsAppended }) => {

    return { unSelectedGuards, dnd: { guardsInRotation: { ...guardData.dnd.guardsInRotation, items: guardsInRotation }, guardsAppended: { ...guardData.dnd.guardsAppended, items: guardsAppended } } }
  }

  const addUnSelectedGuardToRotation = (guardData, setGuardData, e) => {
    var guardId = e.currentTarget.value * 1; //convert to number
    var selected = guardData.unSelectedGuards.filter(d => d.guardId === guardId);

    if (selected.length) {
      var addGuard = selected[0];
      const unSelectedGuards = guardData.unSelectedGuards.filter(d => d.guardId !== guardId);
      guardData.dnd.guardsInRotation.items.push(addGuard);
      guardData.dnd.guardsInRotation.items.forEach(d => d.appended = false);
      debug('addUnSelectedGuardToRotation', guardData.unSelectedGuards, addGuard)

      refreshGuards(buildData({ guardData, unSelectedGuards, guardsInRotation: guardData.dnd.guardsInRotation.items, guardsAppended: guardData.dnd.guardsAppended.items }), setGuardData, true);
    }
  }
  const removeFromRotation = (guardData, setGuardData, guard) => {
    const guardsInRotation = guardData.dnd.guardsInRotation.items.filter(d => d.guardId !== guard.guardId);
    const guardsAppended = guardData.dnd.guardsAppended.items.filter(d => d.guardId !== guard.guardId);
    const unSelectedGuards = guardData.unSelectedGuards.concat(guard);
    refreshGuards(buildData({ guardData, unSelectedGuards, guardsInRotation, guardsAppended }), setGuardData, true);
  }

  const loadData = async (setGuardData) => {
    const guardsResult = await httpHandler('GET', '/api/rotation');
    if (guardsResult && !guardsResult.error) {
      const guards = guardsResult?.data?.guardsInRotation ?? [];
      const unSelectedGuards = guardsResult?.data?.unSelectedGuards ?? [];
      const guardsInRotation = guards.filter(d => !d.appended);
      const guardsAppended = guards.filter(d => d.appended);
      refreshGuards(buildData({ guardData, unSelectedGuards, guardsInRotation, guardsAppended }), setGuardData);
      return true;
    }
    else if (guardsResult?.expired) {
      return false;
    }
  }

  useEffect(() => {
    loadData(setGuardData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGuardData])


  return (
    <>
      <Form className="container">
        <Row className="card card-header">
          <Col className="narrowcol">
            <span className="header">
              Vagt rotation
            </span>
          </Col>
          <Col className="right narrowcol">
            <div className="guardsSelector">
              {
                guardData.unSelectedGuards.length !== 0 &&
                <select onChange={e => addUnSelectedGuardToRotation(guardData, setGuardData, e)} className="form-control">
                  <option value="">Tilføj vagt</option>
                  {guardData.unSelectedGuards.map((d) => <option key={d.guardId} value={d.guardId}>{d.name}</option>)}
                </select>
              }
            </div>
          </Col>
        </Row>
        <Container>
          <Row className="card-body">
            <div className="card-body">
              <div className="row sortable">
                {
                  guardData.dnd.guardsInRotation.items?.length !== 0 && (
                    <DragDropContext
                      onDragEnd={result => onDragEnd(result, guardData, setGuardData)}
                    >
                      {Object.entries(guardData.dnd).map(([columnId, column], index) => {
                        return (
                          <div className="panel panel-info cols2" key={columnId}>
                            <h2>{column.name}</h2>
                            <div className="panel-body">
                              <Droppable droppableId={columnId} key={columnId}>
                                {(provided, snapshot) => {
                                  return (
                                    <div className="ul"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "lightblue"
                                          : "lightgrey"
                                      }}
                                    >
                                      {column.items.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item.guardId + ""}
                                            draggableId={item.guardId + ""}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div className="li"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    userSelect: "none",
                                                    padding: '8px',
                                                    margin: "0 0 4px 0",
                                                    backgroundColor: snapshot.isDragging
                                                      ? "#263B4A"
                                                      : "#456C86",
                                                    color: "white",
                                                    ...provided.draggableProps.style
                                                  }}
                                                >
                                                  {item.name}
                                                  <span onClick={() => removeFromRotation(guardData, setGuardData, { guardId: item.guardId, name: item.name })}>X</span>
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </div>
                          </div>
                        );
                      })}
                    </DragDropContext>
                  )
                }
              </div>
              {
                guardData.dnd.guardsInRotation.items?.length === 0 && (
                  <Col className="form-group row">
                    <div className="col-sm-3">
                      Henter data..
                    </div>
                  </Col>
                )
              }
            </div>
          </Row>
        </Container>
      </Form>
    </>
  );
}


export default Rotation;