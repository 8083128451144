import { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation, Link } from "react-router-dom";
import config from "../Config";
import { useAuthContext } from "../Utils/AuthProvider";

function BasicExample() {
    const { logout, user, authenticated } = useAuthContext();
    const location = useLocation();

    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const logoutHandler = () => {
        logout();
    };

    return (
        <>
            {
                authenticated && (
                    <Navbar expand="lg" variant={`${config.testing ? "dark bg-danger" : "dark bg-primary"}`}>
                        <Container>
                            <Link to="/" className="navbar-brand">Vagt Telefon {config.testing ? "[---" + config.appEnv + "---]" : ""}</Link>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Link to="/plan" className={"nav-link" + (url === "/plan" ? " active" : "")}>Plan</Link>
                                    {
                                        user.isAdmin &&
                                        <>
                                            <Link to="/rotation" className={"nav-link" + (url === "/rotation" ? " active" : "")} visible="false">Rotation</Link>
                                            <Link to="/guard" className={"nav-link" + (url === "/guard" ? " active" : "")}>Vagter</Link>
                                            <Link to="/holidays" className={"nav-link" + (url === "/holidays" ? " active" : "")}>Helligdage</Link>
                                            <Link to="/user" className={"nav-link" + (url === "/user" ? " active" : "")}>Brugere</Link>
                                        </>
                                    }
                                    {
                                        user.isSuperAdmin &&    
                                        <>
                                            <Link to="/settings" className={"nav-link" + (url === "/settings" ? " active" : "")}>Opsætning</Link>
                                        </>
                                    }
                                    <Nav.Link href="#" onClick={logoutHandler}>Log af, {user.userName}</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                )
            }
        </>
    );
}

export default BasicExample;