import React, { useEffect, useState } from 'react';
import { Grid, _ } from "gridjs-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";

const User = () => {
  const { info } = Log();

  const { httpHandler } = useAuthContext();

  const [edit, setEdit] = useState({
    id: 0,
    name: '',
    email: '',
    active: false,
    admin: false
  });

  const [loggedinUser, setLoggedinUser] = useState({}); 

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    items: []
  });

  const deleteItem = async ({ setData, id }) => {
    const deleteResult = await httpHandler('DEL', '/api/user?id=' + id);
    if (deleteResult && !deleteResult.error) {
      await loadData({ setData });
    }
  }
  const updateItem = async () => {
    const updateResult = await httpHandler('PATCH', '/api/user', { item: edit });

    if (updateResult && !updateResult.error) {
      setEdit({
        id: 0,
        name: '',
        email: '',
        active: false,
        admin: false
      });
      await loadData({ setData });
    }
  }

  const editItem = async ({ id }) => {
    const items = data.items.filter(d => d.id === id);
    if (items.length) {
      const editItem = {
        id,
        ...items[0]
      }

      setEdit({ ...editItem });
    }
  }

  const loadData = async ({ setData }) => {
    info(new Date(), 'start loading data from api');
    setLoading(true);
    const result = await httpHandler('GET', '/api/user');
    if (result && !result.error) {
      const items = result?.data?.users ?? [];
      setLoggedinUser(result?.data?.user ?? {});

      info(new Date(), 'finished loading data from api');
      setData({
        items
      });

      setLoading(false);
    }
  }

  useEffect(() => {
    loadData({ setData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData])

  return (
    <>
      <Form className="container container-wide">
        <Row className="card card-header">
          <Col>
            <span className="header">
              Brugere
            </span>
          </Col>
        </Row>
        {
          data.items?.length === 0 && (
            <Container>
              <Row className="card-body">
                <Col>
                  Henter data..
                </Col>
              </Row>
            </Container>
          )
        }
        {
          edit?.id === 0 && !loading && (
            <>
              <Container className="container-wide">
                <Row className="card-body">
                  <Col sm>
                    <Grid
                      data={
                        data.items.map((d) => [d.name, d.email, _(<>{d.active ? "Ja" : "Nej"}</>), _(<>{d.admin ? "Ja" : "Nej"}</>), _(<>{d.superAdmin ? "Ja" : "Nej"}</>),
                        _(
                          <Row className='last'>
                            <Col className="col-auto">
                              <Button variant="warning" onClick={() => {
                                editItem({ id: d.id })
                              }}>Ret</Button>
                            </Col>
                            <Col className="col-auto">
                              <Button variant="danger" onClick={() => {
                                if (window.confirm('Brugeren slettes ikke, men gøres inaktiv.')) deleteItem({ setData, id: d.id })
                              }}>Deaktiver</Button>
                            </Col>
                          </Row>
                        )
                        ])
                      }

                      columns={[
                        {
                          name: 'Navn',
                          sort: {
                            enabled: true
                          }
                        },
                        {
                          name: 'Email',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Aktiv',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Admin',
                          sort: {
                            enabled: false
                          }
                        },                        
                        {
                          name: 'Super',
                          sort: {
                            enabled: false
                          },
                          hidden: !loggedinUser?.isSuper
                        },
                        {
                          id: 'actions',
                          sort: {
                            enabled: false
                          }
                        }]}
                      sort={true}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )
        }
        {
          edit?.id !== 0 && (
            <>
              <Container className="container-wide">
                <Row className="card-body">
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>{edit.name} ({edit.email})</Form.Label>

                  </Form.Group>
                </Row>
                <Row className="card-body">
                  <Form.Group className="position-relative mb-3">
                    <Form.Check
                      id="validationCustom04"
                      label="Aktiv" checked={edit.active} onChange={e => setEdit({ ...edit, active: !edit.active })}
                    />
                  </Form.Group>
                </Row>
                <Row className="card-body">
                  <Form.Group className="position-relative mb-3">
                    <Form.Check
                      id="validationCustom05"
                      label="Administrator" checked={edit.admin} onChange={e => setEdit({ ...edit, admin: !edit.admin })}
                    />
                  </Form.Group>
                </Row>
                {
                  loggedinUser?.isSuper &&
                  <Row className="card-body">
                    <Form.Group className="position-relative mb-3">
                      <Form.Check
                        id="validationCustom06"
                        label="Super admin" checked={edit.superAdmin} onChange={e => setEdit({ ...edit, superAdmin: !edit.superAdmin })}
                      />
                    </Form.Group>
                  </Row>
                }
                <Row className="card-body">
                  <Form.Group as={Col} md="2">
                    <br />
                    <Button variant="danger" className="col-sm" onClick={() => { setEdit({ id: 0, name: '', email: '', active: false, admin: false, superAdmin: false }) }}>Fortryd, retur til liste</Button>
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <br />
                    <Button variant="success" onClick={() => { updateItem(); }}>Gem</Button>
                  </Form.Group>
                </Row>
              </Container>

            </>
          )
        }
      </Form>
    </>
  );

}
export default User;