import NavBar from "./Nav";
import { Routes, Route, Navigate } from "react-router-dom";

import Planner from "./Planner";
import Rotation from "./Rotation";
import Guard from "./Guard";
import Settings from "./Settings";
import Holidays from "./Holidays";
import User from "./User";

export const PageLayout = (props) => {

    return (
        <>
            <NavBar />
            <main className="py-4">
                <Routes>
                    <Route exact path="/plan" element={<Planner />} />
                    <Route exact path="/rotation" element={<Rotation />} />
                    <Route path="/guard" element={<Guard />} />
                    <Route path="/holidays" element={<Holidays />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/user" element={<User />} />

                    <Route exact path="*" element={<Navigate to="/plan" replace />} />
                </Routes>
            </main>
            <br />
            {props.children}
        </>
    );
};