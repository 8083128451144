import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Grid, _ } from "gridjs-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';
import 'moment/locale/da';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";

const Planner = () => {
  const { info } = Log();

  moment().tz("Europe/Copenhagen").format();

  const { httpHandler } = useAuthContext();

  const [disable, setDisable] = React.useState(false);
  const [searchFromDate, setSearchFromDate] = useState(moment().startOf('day').startOf('week').toDate());
  const [searchToDate, setSearchToDate] = useState(moment().startOf('day').startOf('week').add(30, 'day').toDate());
  const [newStartTime, setNewStartTime] = useState(moment().startOf('day').toDate());

  const [editPlan, setEditPlan] = useState({
    planId: 0,
    guards: [],
    startTime: new Date()
  });

  const [error, setError] = useState("");

  const [loadingPlans, setLoadingPlans] = useState(true);

  const [planData, setPlanData] = useState({
    plans: [],
    guards: [],
    newestPlan: {
      guardId: 0,
      turnName: ''
    }
  });

  const changeGuard = (planData, setPlanData, e) => {
    var guardId = e.currentTarget.value * 1; //convert to number
    const guard = planData.guards.filter(d => d.guardId === guardId)[0];
    const newestPlan = { ...planData.newestPlan, guardId: guardId, initials: guard.initials };
    setPlanData({ ...planData, newestPlan });
  }
  const changeFrom = (date) => {
    setSearchFromDate(date);
    let searchTo = searchToDate;
    if (date > searchToDate) {
      searchTo = moment(date).add(21, 'day').toDate();
      setSearchToDate(searchTo);
    }

    loadData({ setPlanData, search: { from: date, to: searchTo }, replaceStartTime: false });
  }
  function changeTo(date) {
    setSearchToDate(date);
    loadData({ setPlanData, search: { from: searchFromDate, to: date }, replaceStartTime: false });
  }
  const changeStart = (date) => {
    setNewStartTime(date);
  }
  const formattedTurnName = (startTime, initials) => {
    return moment(startTime).format('L').replaceAll(/\./ig, '-') + ' - (' + initials + ')';
  }

  const addPlan = async ({ setDisable, setPlanData }) => {
    setDisable(true);
    setPlanData(planData)
    const addPlanResult = await httpHandler('PUT', '/api/planner', { plan: { ...planData.newestPlan, startTime: newStartTime } });
    if (addPlanResult && !addPlanResult.error) {
      await loadData({ setPlanData, search: { from: searchFromDate, to: searchToDate } });
      setDisable(false);
      setError('');
      return true;
    }
    else if (addPlanResult?.error && addPlanResult?.code === 422) {
      setError('Der eksisterer allerede en plan med dette tidspunkt');
      setDisable(false);
    }
    else if (addPlanResult?.expired) {
      return false;
    }
  }
  const deletePlan = async ({ setPlanData, planId }) => {
    const deletePlanResult = await httpHandler('DEL', '/api/planner?id=' + planId);
    if (deletePlanResult && !deletePlanResult.error) {
      await loadData({ setPlanData, search: { from: searchFromDate, to: searchToDate } });
    }
  }
  const updatePlan = async () => {
    const updatePlanResult = await httpHandler('PATCH', '/api/planner?id=' + editPlan.planId, { plan: editPlan });
    if (updatePlanResult && !updatePlanResult.error) {
      setEditPlan({ ...editPlan, planId: 0 })
      await loadData({ setPlanData, search: { from: searchFromDate, to: searchToDate } });
    }
    else if (updatePlanResult?.error) {
      if ( updatePlanResult?.code === 422) {
        setError('Der eksisterer allerede en plan med dette tidspunkt');
        setDisable(false);
      }
      if ( updatePlanResult?.code === 423) {
        setError('Gamle planer kan ikke redigeres');
        setDisable(false);
      }
    }
  }
  const updatePlanGuard = async (plan) => {
    const guard = planData.guards.filter(d => d.guardId === plan.guardId)[0];
    const updatePlanGuardResult = await httpHandler('PATCH', '/api/planner?id=' + plan.planId, { plan: { ...plan, initials: guard.initials } });
    if (updatePlanGuardResult && !updatePlanGuardResult.error) {
      await loadData({ setPlanData, search: { from: searchFromDate, to: searchToDate } });
    }
    else if (updatePlanGuardResult?.error) {
      if ( updatePlanGuardResult?.code === 423) {
        setError('Gamle planer kan ikke redigeres');
        setDisable(false);
      }
    }
  }

  const loadDetails = async ({ planId, turn_name, start_time, primary_guard, old_guard, updated_at, updated_by }) => {
    setError('');
    const detailsResult = await httpHandler('GET', '/api/planner?id=' + planId);
    if (detailsResult && !detailsResult.error) {
      const guards = detailsResult?.data?.guards ?? [];
      const allActiveGuards = detailsResult?.data?.allActiveGuards ?? [];

      setEditPlan({
        ...editPlan,
        planId,
        turn_name,
        startTime: moment(start_time).toDate(),
        primary_guard, old_guard, updated_at, updated_by,
        guards,
        allActiveGuards,
        selectableNewGuards: allActiveGuards
      });
    }
  }
  const removeFromPlan = ({ editPlan, setEditPlan, guard }) => {
    const guards = editPlan.guards.filter(d => d.tmpId !== guard.tmpId);

    setEditPlan({ ...editPlan, guards, selectableNewGuards: editPlan.allActiveGuards });
  }

  const onDragEnd = (result, editPlan, setEditPlan) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const column = editPlan.guards;
    const copiedItems = [...column];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    setEditPlan({
      ...editPlan,
      guards: copiedItems
    });
  };

  const loadData = async ({ setPlanData, search, replaceStartTime = true }) => {
    info(new Date(), 'start loading data from api', search);
    setLoadingPlans(true);
    const planResult = await httpHandler('GET', '/api/planner', search);
    if (planResult && !planResult.error) {
      const plans = planResult?.data?.plans ?? [];
      const guards = planResult?.data?.guards ?? [];
      const newestPlan = planResult?.data?.newestPlan;

      if (planResult?.data?.from)
        setSearchFromDate(new Date(planResult?.data?.from));
      if (planResult?.data?.to)
        setSearchToDate(new Date(planResult?.data?.to));

      let nextGuardIndex = 0;
      for (let i = 0; i < guards.length; i++) {
        if (guards[i].guardId === newestPlan.guardId) {
          nextGuardIndex = i;
          break;
        }
      }
      nextGuardIndex = (nextGuardIndex === guards.length - 1) ? 0 : nextGuardIndex + 1;

      const newPlanData = {
        plans, guards,
        newestPlan: {
          ...planData.newestPlan,
          guardId: guards[nextGuardIndex]?.guardId ?? 0,
          initials: guards[nextGuardIndex]?.initials ?? ''
        }
      }
      if (replaceStartTime) {
        setNewStartTime(moment(newestPlan.startTime).startOf('day').add(1, 'day').toDate());
        newPlanData.newestPlan.turnName = formattedTurnName(newPlanData.newestPlan.startTime, guards[nextGuardIndex]?.initials ?? '');
      }

      info(new Date(), 'finished loading data from api', search);
      setPlanData(newPlanData);
      setLoadingPlans(false);
    }
  }

  useEffect(() => {
    loadData({ setPlanData, search: { from: searchFromDate, to: searchToDate } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlanData])

  return (
    <>
      <Form className="container container-wide">
          <Row className="card card-header">
            <Col>
              <span className="header">
                Plan
              </span>
            </Col>
            {
              editPlan?.planId === 0 && (
                <div className="col right last">
                  <div className="planSearch col-sm-2 bold">
                    Datofilter
                  </div>
                  <div className="planSearch col-sm-2">
                    <DateTimePicker value={searchFromDate} onChange={date => changeFrom(date)} clearIcon={null} />
                  </div>
                  <div className="planSearch col-sm-2">
                    <DateTimePicker value={searchToDate} onChange={date => changeTo(date)} clearIcon={null} />
                  </div>
                </div>
              )
            }
          </Row>
        {
          editPlan?.planId === 0 && planData.guards?.length > 0 && (
            <>
              <Container className="container-wide">
                <Row className="card-body">
                  <Col className="col-sm">
                    <DateTimePicker value={newStartTime} onChange={date => changeStart(date)} clearIcon={null} />
                  </Col>
                  <Col sm={5} className="col-sm">
                    <select onChange={e => changeGuard(planData, setPlanData, e)} className="form-control" value={planData.newestPlan.guardId} >
                      {
                        planData.guards.map((d) =>
                          <option key={d.guardId} value={d.guardId}>
                            {d.name}
                          </option>)
                      }
                    </select>
                  </Col>
                  <Col sm className="center last">
                    <Button variant="primary" disabled={disable} onClick={() => addPlan({ setDisable, setPlanData })}>Tilføj ny plan</Button>
                  </Col>
                </Row>
                <Row className="card-body">
                  {
                    error && <div className="card-body">
                      <small style={{ color: 'red' }}>{error}</small>
                    </div>
                  }
                </Row>
              </Container>
              {
                loadingPlans && (
                  <Container>
                    <Row className="card-body">
                      <Col>
                        Henter data..
                      </Col>
                    </Row>
                  </Container>
                )
              }
              {
                !loadingPlans && (
                  <Container className="container-wide">
                    <Row className="card-body">
                      <Col sm>
                        <Grid
                          data={
                            planData.plans.map((d) => [
                              _(<div className={moment(d.start_time).week()%2===0 ? 'weekEven txt' : 'weekOdd txt'}>
                                {moment(d.start_time).format('L')}
                              </div>
                              ),
                              _(<div className={moment(d.start_time).week()%2===0 ? 'weekEven txt' : 'weekOdd txt'}>
                                {
                                  moment(d.start_time) >= moment().startOf('day') &&  (<select onChange={e => updatePlanGuard({ planId: d.id, startTime: d.start_time, guardId: e.currentTarget.value * 1 })} value={d.guard_id} >
                                    {
                                      planData.guards.map((d2) =>
                                        <option key={d2.guardId} value={d2.guardId}>
                                          {d2.initials}
                                        </option>)
                                    }
                                  </select>
                                  )
                                }
                                {
                                  moment(d.start_time) < moment().startOf('day') &&  (<div>
                                    {d.primary_guard}
                                  </div>
                                  )
                                }
                              
                              </div>),
                              _(<div className={moment(d.start_time).week()%2===0 ? 'weekEven txt' : 'weekOdd txt'}>
                                {
                                  d.list && (d.list)
                                }
                                {
                                  !d.list && (<>
                                    Aktuel rotationsliste&nbsp;
                                    {
                                      d.old_guard && (<>
                                        - oprindelig vagt: {d.old_guard}
                                      </>
                                      )
                                    }
                                    </>)
                                }
                              </div>
                              ),
                              _(
                                <>
                                  {
                                    moment(d.start_time) >= moment().startOf('day') && (
                                      <div className={moment(d.start_time).week()%2===0 ? 'weekEven' : 'weekOdd'}>
                                        {
                                          moment(d.start_time) >= moment().startOf('day') && (
                                            <Row className='last'>
                                              <Col className="col-auto">
                                                <Button variant="warning" onClick={() => {
                                                  loadDetails({ planId: d.id, ...d })
                                                }}>Ret</Button>
                                              </Col>
                                              <Col className="col-auto">
                                                <Button variant="danger" onClick={() => {
                                                  if (window.confirm('Er du sikker på at du vil slette denne plan?')) deletePlan({ setPlanData, planId: d.id })
                                                }}>Slet</Button>
                                              </Col>
                                            </Row>
                                          )
                                        }
                                      </div>
                                    )
                                  }
                                  {
                                    moment(d.start_time) < moment().startOf('day') && (
                                      <div className={moment(d.start_time).week()%2===0 ? 'weekEven txt' : 'weekOdd txt'}>
                                        &nbsp;
                                      </div>
                                    )
                                  }
                                </>
                              )
                            ])
                          }

                          columns={[
                            {
                              name: 'Starttid',
                              sort: {
                                compare: (a, b) => {
                                  if (moment(a, "DD.MM.YYYY") > moment(b, "DD.MM.YYYY")) {
                                    return 1;
                                  } else if (moment(b, "DD.MM.YYYY") > moment(a, "DD.MM.YYYY")) {
                                    return -1;
                                  } else {
                                    return 0;
                                  }
                                }
                              },
                              flex: 1
                            },
                            {
                              name: 'Vagt',
                              sort: {
                                enabled: false
                              },
                              flex: 1
                            },
                            {
                              name: 'Sekundære vagter i ringekæde',
                              sort: {
                                enabled: false
                              },
                              flex: 1
                            },
                            {
                              id: 'actions',
                              sort: {
                                enabled: false
                              },
                              flex: 1
                            }]}
                          sort={true}
                        />
                      </Col>
                    </Row>
                  </Container>
                )
              }
            </>
          )
        }
        {
          editPlan?.planId !== 0 && (
            <>
              <Container className="container-wide">
                <Row className={"card-body row " + (editPlan.startTime ? "" : "error")}>
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>Starttid</Form.Label>
                    <DateTimePicker value={editPlan.startTime} onChange={date => setEditPlan({ ...editPlan, startTime: date })} clearIcon={null} />
                    <div className="errorMessage">
                      Starttid skal angives
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    {
                      editPlan.selectableNewGuards.length !== 0 ?
                        <select onChange={e => {
                          var newGuard = { ...editPlan.allActiveGuards.filter(d => d.guardId === e.currentTarget.value * 1)[0] };
                          var maxId = 0;
                          for (let tmpGuard of editPlan.guards) {
                            if (tmpGuard.tmpId > maxId)
                              maxId = tmpGuard.tmpId;
                          }
                          newGuard.tmpId = maxId + 1;
                          setEditPlan(
                            {
                              ...editPlan,
                              guards: editPlan.guards.concat([newGuard]),
                              selectableNewGuards: editPlan.allActiveGuards
                            });

                        }} className="form-control">
                          <option value="">Tilføj sekundær vagt</option>
                          {
                            editPlan.selectableNewGuards.map(
                              (d) =>
                                <option key={d.guardId} value={d.guardId}>{d.name}</option>
                            )
                          }
                        </select>
                        : null
                    }
                  </Form.Group>
                </Row>
              </Container>
              <Container className="container-wide">
                <Row className="card-body">
                  <div>
                    Vagt: {editPlan.primary_guard}
                  </div>
                  {
                    editPlan.updated_by && <div>
                      Vagtplanen er ændret d. {moment(editPlan.updated_at).format('L')} af {editPlan.updated_by}
                    </div>
                  }
                  {
                    editPlan.old_guard && <div>
                       Oprindelig vagt: {editPlan.old_guard}
                    </div>
                  }
                </Row>
              </Container>
              <Container className="container-wide">
                <Row className="card-body">
                  <div>
                  Sekundære vagter i ringekæde
                  </div>
                  <Col className={"form-group row " + (editPlan.guards?.length ? "" : "error")}>
                    <div className="row sortable" ng-show="loaded">
                      <DragDropContext
                        onDragEnd={result => onDragEnd(result, editPlan, setEditPlan)}
                      >
                        <div className="panel panel-info cols1">
                          <div className="panel-body">
                            <Droppable droppableId="guards" key="guards">
                              {(provided, snapshot) => {
                                return (
                                  <div className="ul"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? "lightblue"
                                        : "lightgrey"
                                    }}
                                  >
                                    {editPlan.guards.map((item, index) => {
                                      return (
                                        <Draggable
                                          key={item.tmpId + ""}
                                          draggableId={item.tmpId + ""}
                                          index={index}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div className="li"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                  userSelect: "none",
                                                  padding: '8px',
                                                  margin: "0 0 4px 0",
                                                  backgroundColor: snapshot.isDragging
                                                    ? "#263B4A"
                                                    : "#456C86",
                                                  color: "white",
                                                  ...provided.draggableProps.style
                                                }}
                                              >
                                                {item.name}
                                                <span onClick={() => removeFromPlan({ editPlan, setEditPlan, guard: item })}>Fjern</span>
                                              </div>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </div>
                        </div>
                      </DragDropContext>
                    </div>
                    <div className="errorMessage">
                      Der skal være mindst en vagt på en vagtplan
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container className="container-wide">
                <Row className="card-body">
                  <Form.Group as={Col} xs lg="2">
                    <Button variant="danger" className="col-sm" onClick={() => { setEditPlan({ ...editPlan, planId: 0 }); setError(''); }}>Fortryd, retur til liste</Button>
                  </Form.Group>
                  <Form.Group as={Col} xs lg="2">
                    <Button variant="success" disabled={!(editPlan.startTime && editPlan.guards?.length)} onClick={() => { updatePlan(); }}>Gem</Button>
                  </Form.Group>
                </Row>
                <Row className="card-body">
                  {
                    error && <div className="card-body">
                      <small style={{ color: 'red' }}>{error}</small>
                    </div>
                  }
                </Row>
              </Container>
            </>
          )
        }
      </Form>
    </>
  );

}
export default Planner;