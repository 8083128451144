import { createContext, useContext, useState } from "react";
import HttpClient from '../Utils/HttpClient';
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import config from '../Config';

const AuthContext = createContext()
const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const { instance } = useMsal();
  const [authenticated, setAuthenticated] = useState(false);
  const [versionInfo, setVersionInfo] = useState("");
  const [user, setUser] = useState({});

  const updateVersion = (version) => {
    setVersionInfo(version + "/" + config.version);
  }

  const logout = () => {
    instance.logoutRedirect();
  }

  const httpHandler = async (method, url, data) => {

    const tokenRequest = {
      account: instance.getActiveAccount() || null,
      ...loginRequest
    };

    let authToken = await new Promise(callback => {
      instance.acquireTokenSilent(tokenRequest)
        .then((response) => {
          return callback(response.accessToken);
        })
        .catch(e => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.loginRedirect();
          }
          else {
            console.log('error getting authtoken', e);
          }
          return callback(undefined);
        });
    });

    const httpResult = await callHttp(method, authToken, url, data);

    return httpResult;
  }

  const callHttp = async (method, authToken, url, data = {}) => {
    const { httpClientGet, httpClientPost, httpClientPatch, httpClientPut, httpClientDelete } = HttpClient();

    switch (method) {
      case "GET":
        return await httpClientGet(authToken, url, data, updateVersion, setUser, setAuthenticated);
      case "POST":
        return await httpClientPost(authToken, url, data);
      case "PATCH":
        return await httpClientPatch(authToken, url, data);
      case "PUT":
        return await httpClientPut(authToken, url, data);
      case "DEL":
        return await httpClientDelete(authToken, url, data);
      default:
        return undefined;
    }
  }

  return <AuthContext.Provider value={{ authenticated, versionInfo, user, logout, httpHandler }}>
    {children}
  </AuthContext.Provider>
}

export { useAuthContext, AuthProvider }