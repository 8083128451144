import { useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from './containers/PageLayout';
import { useAuthContext } from "./Utils/AuthProvider";
import { useLocation } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';
import './css/bootstrap-theme.min.css'
import './css/style.css';

export default function App() {
  const { versionInfo } = useAuthContext();
  const { instance } = useMsal();
  const location = useLocation();

  function handleLogin() {
    instance.handleRedirectPromise().then(authResult => {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        instance.loginRedirect(loginRequest);
      } else if (currentAccounts.length > 0) {
        instance.setActiveAccount(currentAccounts[0]);
      }
    });
  }


  useEffect(() => {
    if (location.pathname === "/") {
      handleLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <AuthenticatedTemplate>
        <PageLayout />
        <div version={versionInfo}></div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Form className="container">
          <Container className="col-md-6">
            <Row className="card-body">
              <Col className="center">
                <br />Du er nu logget ud.<br />
                <br />
                <Button variant="primary" onClick={e => handleLogin()}>Log ind</Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </UnauthenticatedTemplate>
    </>
  );
}