import axios from 'axios';
import config from '../Config';

const getOptions = ({ authToken } = {}) => {
  let options = {
    baseURL: config.baseUrl
  }

  options.headers = {
    'x-token': authToken
  }

  return options;
}

function HttpClient() {
  function errorHandler(error, res) {
    if (error?.response?.status === 498) {
      res({ error: true, expired: true, message: 'Login expired' });
    }
    else if (error?.response?.status === 406) {
      alert('Bruger mangler godkendelse')
    }
    else
      res({ error: true, message: 'Something went wrong', code: error?.response?.status });
  }

  const httpClientGet = (authToken, url, data, updateVersion, setUser, setAuthenticated) => {
    const options = getOptions({ authToken });
    return new Promise((res) => {
      axios
        .create(options)
        .get(url, { params: data })
        .then(response => {
          if (response?.data?.apiVersion) {
            updateVersion(response?.data?.apiVersion)
          }
          if (response?.data?.user) {
            setUser({ userName: response?.data?.user?.name, isAdmin: response?.data?.user?.isAdmin, isSuperAdmin: response?.data?.user?.isSuper })
            setAuthenticated(true);
          }
          res(response);
        })
        .catch(e => errorHandler(e, res))
    });
  }
  const httpClientPost = (authToken, url, data) => {
    return new Promise((res) => {
      const options = getOptions({ authToken });
      axios
        .create(options)
        .post(url, data)
        .then(response => {
          res(response);
        })
        .catch(e => errorHandler(e, res))
    });
  }
  const httpClientPatch = (authToken, url, data) => {
    return new Promise((res) => {
      const options = getOptions({ authToken });
      axios
        .create(options)
        .patch(url, data)
        .then(response => {
          res(response);
        })
        .catch(e => errorHandler(e, res))
    });
  }
  const httpClientPut = (authToken, url, data) => {
    return new Promise((res) => {
      axios
        .create(getOptions({ authToken }))
        .put(url, data)
        .then(response => {
          res(response);
        })
        .catch(e => errorHandler(e, res))
    });
  }
  const httpClientDelete = (authToken, url) => {
    return new Promise((res) => {
      axios
        .create(getOptions({ authToken }))
        .delete(url)
        .then(response => {
          res(response);
        })
        .catch(e => errorHandler(e, res))
    });
  }

  return {
    httpClientGet(authToken, url, data, updateVersion, setUser, setAuthenticated) {
      return httpClientGet(authToken, url, data, updateVersion, setUser, setAuthenticated);
    },
    httpClientPost(authToken, url, data) {
      return httpClientPost(authToken, url, data);
    },
    httpClientPatch(authToken, url, data) {
      return httpClientPatch(authToken, url, data);
    },
    httpClientPut(authToken, url, data) {
      return httpClientPut(authToken, url, data);
    },
    httpClientDelete(authToken, url) {
      return httpClientDelete(authToken, url);
    }
  }
}
export default HttpClient;