
import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env["REACT_APP_AZURE_CLIENT_ID"],
    authority: 'https://login.microsoftonline.com/15aa23df-65c3-4003-8940-4230b9bf7909',
    redirectUri: '/',
    postLogoutRedirectUri: '/loggedout',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {
        console.log(message)
      },
      logLevel: LogLevel.Error
    }
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};
