import React, { useEffect, useState } from 'react';
import { Grid, _ } from "gridjs-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";

const Guard = () => {
  const { debug } = Log();

  const { httpHandler } = useAuthContext();
  const [disable, setDisable] = React.useState(false);
  const [edit, setEdit] = useState({
    guardId: 0,
  });
  const [newData, setNewData] = useState({
    name: '',
    initial_name: '',
    number: ''
  });
  const [data, setData] = useState({
    guards: []
  });

  const addGuard = async ({ setData }) => {
    setDisable(true);
    const addGuardResult = await httpHandler('PUT', '/api/guard', { guard: { ...newData } });
    if (addGuardResult && !addGuardResult.error) {
      setNewData({
        name: '',
        initial_name: '',
        number: ''
      });
      await loadData({ setData });
      setDisable(false);
    }
  }

  const updateGuard = async ({ setData }) => {
    const updateResult = await httpHandler('PATCH', '/api/guard?id=' + edit.guardId, { guard: edit });
    debug('updateResult', updateResult)
    if (updateResult && !updateResult.error) {
      setEdit({ guardId: 0 });
      await loadData({ setData });
    }
  }

  const loadData = async ({ setData }) => {
    const guardResult = await httpHandler('GET', '/api/guard');
    if (guardResult && !guardResult.error) {
      const guards = guardResult?.data?.guards ?? [];

      setData({ ...data, guards });
    }
  }

  useEffect(() => {
    loadData({ setData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData])

  return (
    <>
      <Form className="container container-wide">
        <Row className="card card-header">
          <Col>
            <span className="header">
              Vagter
            </span>
          </Col>
        </Row>
        {
          data.guards?.length === 0 && (
            <Container>
              <Row className="card-body">
                <Col>
                  Henter data..
                </Col>
              </Row>
            </Container>
          )
        }
        {
          edit?.guardId === 0 && data.guards?.length !== 0 && (
            <>
              <Container>
                <Row className="card-body">
                  <Col sm>
                    <Form.Control type="text" placeholder="Navn" value={newData.name} maxLength="50" onChange={e => setNewData({ ...newData, name: e.currentTarget.value })} />
                  </Col>
                  <Col sm>
                    <Form.Control type="text" placeholder="Initialer" value={newData.initial_name} maxLength="2" onChange={e => setNewData({ ...newData, initial_name: e.currentTarget.value.toUpperCase() })} />
                  </Col>
                  <Col sm>
                    <Form.Control type="text" placeholder="Nummer" value={newData.number} maxLength="20" onChange={e => setNewData({ ...newData, number: e.currentTarget.value })} />
                  </Col>
                  <Col sm className="center last">
                    <Button variant="primary" disabled={disable || newData.name === "" || newData.initial_name === "" || newData.number === ""} onClick={() => addGuard({ setData })}>Tilføj ny vagt</Button>
                  </Col>
                </Row>
              </Container>
              <Container className="container-wide">
                <Row className="card-body">
                  <Col sm>
                    <Grid
                      data={
                        data.guards.map((d) => [
                          d.name, d.initial_name, d.number, _(<>{d.active ? "Ja" : "Nej"}</>),
                          _(<Row className="last">
                            <Col className="col-auto">
                              <Button variant="warning" onClick={(e) => {
                                setEdit({ ...d });
                                debug("edit " + d.guardId);
                              }}>Ret</Button>
                            </Col>
                          </Row>
                          )
                        ])
                      }

                      columns={[
                        {
                          name: 'Navn',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Initialer',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Nummer',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Aktiv',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          id: 'actions',                          
                          sort: {
                            enabled: false
                          }
                        }]}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )
        }
        {
          edit?.guardId !== 0 && (
            <Container>
              <Row className="card-body">
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Navn</Form.Label>
                  <Form.Control type="text" placeholder="Navn" value={edit.name} maxLength="50" onChange={e => setEdit({ ...edit, name: e.currentTarget.value })} />
                  <Form.Control.Feedback type="invalid">
                    Navn skal angives
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group as={Col} md="1" controlId="validationCustom02">
                  <Form.Label>Initialer</Form.Label>
                  <Form.Control type="text" placeholder="Initialer" value={edit.initial_name} maxLength="2" onChange={e => setEdit({ ...edit, initial_name: e.currentTarget.value.toUpperCase() })} />
                  <Form.Control.Feedback type="invalid">
                    Initialer skal angives
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group as={Col} md="3" controlId="validationCustom03">
                  <Form.Label>Nummer</Form.Label>
                  <Form.Control type="text" placeholder="Nummer" value={edit.number} maxLength="20" onChange={e => setEdit({ ...edit, number: e.currentTarget.value })} />
                  <Form.Control.Feedback type="invalid">
                    Nummer skal angives
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group className="position-relative mb-3">
                  <Form.Check
                    id="validationCustom04"
                    label="Aktiv" checked={edit.active} onChange={e => setEdit({ ...edit, active: !edit.active })}
                  />
                </Form.Group>
              </Row>
              <Row className="card-body">
                <Form.Group as={Col} md="2">
                  <Button className="btn col-sm btn-danger" onClick={() => { setEdit({ guardId: 0 }) }}>Fortryd, retur til liste</Button>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Button variant="success" disabled={!edit.name || !edit.initial_name || !edit.number} onClick={() => { updateGuard({ setData }); }}>Gem</Button>
                </Form.Group>
              </Row>
            </Container>
          )
        }
      </Form>
    </>
  );

}
export default Guard;