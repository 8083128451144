import React, { useEffect, useState } from 'react';
import { Grid, _ } from "gridjs-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';
import 'moment/locale/da';
import { useAuthContext } from "../Utils/AuthProvider";
import Log from "../Utils/Log";

const Settings = () => {
  const { info } = Log();

  moment().tz("Europe/Copenhagen").format();

  const { httpHandler } = useAuthContext();

  const [disable, setDisable] = React.useState(false);
  const [disableGenerateBtn, setDisableGenerateBtn] = React.useState(false);
  const [disableReplaceBtn, setDisableReplaceBtn] = React.useState(false);

  const [edit, setEdit] = useState({
    id: '',
    key: '',
    value: ''
  });

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    items: []
  });
  
  const [endDate, setEndDate] = useState(moment().startOf('day').toDate());
  const [updateAfterDate, setUpdateAfterDate] = useState(moment().startOf('day').toDate());
  const [updateFromGuard, setUpdateFromGuard] = useState(0);
  const [updateToGuard, setUpdateToGuard] = useState(0);
  const [generateResult, setGenerateResult] = useState(''); 
  const [replaceResult, setReplaceResult] = useState(''); 
  const [activeGuards, setActiveGuards] = useState([]);
  const [allGuards, setAllGuards] = useState([]);

  const generateNewPlans = async () => {
    setDisableGenerateBtn(true);
    setGenerateResult('');
    const result = await httpHandler('PUT', '/api/bulkjobs', { endDate: endDate });
    setDisableGenerateBtn(false);

    if (result && !result.error && result?.data?.endDate) {
      setEndDate(new Date(result?.data?.endDate));
      setGenerateResult(result?.data?.count + ' plane(r) tilføjet');
    }
  }

  const replaceInPlans = async () => {
    setDisableReplaceBtn(true);
    setReplaceResult('');
    const result = await httpHandler('PATCH', '/api/bulkjobs', { updateAfterDate, updateFromGuard, updateToGuard });
    setDisableReplaceBtn(false);

    if (result && !result.error && result?.data) {
      setReplaceResult(result?.data?.count + ' vagt(er) opdateret');
    }
  }

  const addItem = async ({ setDisable, setData }) => {
    setDisable(true);
    const addResult = await httpHandler('PUT', '/api/settings', { item: { ...edit } });
    if (addResult && !addResult.error) {
      await loadData({ setData });
      setDisable(false);
      setEdit({
        id: '',
        key: '',
        value: ''
      });
      return true;
    }
    else if (addResult?.expired) {
      return false;
    }
    else
      setDisable(false);
  }
  const updateItem = async () => {
    const updateResult = await httpHandler('PATCH', '/api/settings', { item: edit });

    if (updateResult && !updateResult.error) {
      setEdit({
        id: '',
        key: '',
        value: ''
      });
      await loadData({ setData });
    }
  }
  const editItem = async ({ id }) => {
    const items = data.items.filter(d => d.key === id);
    if (items.length) {
      const editItem = {
        ...items[0]
      }

      setEdit({ ...editItem });
    }
  }

  const shouldDisable = () => {
    return disable || !edit.key || !edit.value;
  }

  const shouldDisableUpdate = () => {
    return disableReplaceBtn || !updateToGuard || !updateFromGuard;
  }

  const loadData = async ({ setData }) => {
    info(new Date(), 'start loading data from api');
    setLoading(true);
    const result = await httpHandler('GET', '/api/settings');
    const resultBulkJobs = await httpHandler('GET', '/api/bulkjobs');

    if (result && !result.error && resultBulkJobs && !resultBulkJobs.error) {
      const items = result?.data?.items ?? [];
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        element.id = element.key;
      }

      setEndDate(new Date(resultBulkJobs?.data?.endDate));
      setAllGuards(resultBulkJobs?.data?.allGuards);
      setActiveGuards(resultBulkJobs?.data?.activeGuards);
      console.log(resultBulkJobs?.data?.activeGuards, resultBulkJobs?.data?.allGuards)

      info(new Date(), 'finished loading data from api');
      setData({
        items
      });

      setLoading(false);
    }
  }

  useEffect(() => {
    loadData({ setData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData])

  return (
    <>
      <Form className="container container-wide">
        <Row className="card card-header">
          <Col>
            <span className="header">
              Opsætning
            </span>
          </Col>
        </Row>

        {
          data.items?.length === 0 && (
            <Container>
              <Row className="card-body">
                <Col>
                  Henter data..
                </Col>
              </Row>
            </Container>
          )
        }
        {
          edit?.id === "" && !loading && (
            <>
              <Container>
                <Row className="card-body">
                  <Col sm>
                    <Form.Control type="text" placeholder="Key" value={edit.key} maxLength="50" onChange={e => setEdit({ ...edit, key: e.currentTarget.value })} />
                  </Col>
                  <Col sm>
                    <Form.Control type="text" placeholder="Value" value={edit.value} maxLength="250" onChange={e => setEdit({ ...edit, value: e.currentTarget.value })} />
                  </Col>
                  <Col sm className="center last">
                    <Button variant="primary" disabled={shouldDisable()} onClick={() => addItem({ setDisable, setData })}>Tilføj ny opsætning</Button>
                  </Col>
                </Row>
              </Container>

              <Container className="container-wide">
                <Row className="card-body">
                  <Col sm>
                    <Grid
                      data={
                        data.items.map((d) => [d.key, d.value,
                        _(<Row className="last">
                          <Col className="col-auto">
                          <Button variant="warning" onClick={() => {
                            editItem({ id: d.key })
                          }}>Ret</Button>
                          </Col>
                        </Row>
                        )
                        ])
                      }

                      columns={[
                        {
                          name: 'Key',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          name: 'Value',
                          sort: {
                            enabled: false
                          }
                        },
                        {
                          id: 'actions',
                          sort: {
                            enabled: false
                          }
                        }]}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )
        }
        {
          edit?.id !== "" && (
            <>
              <Container className="edit">
                <Row className="card-body">
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>Key</Form.Label>
                    <Form.Control type="text" placeholder="Key" required value={edit.key} onChange={e => setEdit({ ...edit, key: e.currentTarget.value })} maxLength="50" />
                    <Form.Control.Feedback type="invalid">
                      Key skal angives
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Value</Form.Label>
                    <Form.Control type="text" placeholder="Value" required value={edit.value} onChange={e => setEdit({ ...edit, value: e.currentTarget.value })} maxLength="250" />
                    <Form.Control.Feedback type="invalid">
                      Value skal angives
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="card-body">
                  <Form.Group as={Col} md="2">
                    <Button variant="danger" className="col-sm" onClick={() => { setEdit({ id: '', key: '', value: '' }) }}>Fortryd, retur til liste</Button>
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Button variant="success" disabled={shouldDisable()} onClick={() => { updateItem(); }}>Gem</Button>
                  </Form.Group>
                </Row>
              </Container>
            </>
          )
        }
        {
          !loading && (
            <>
              <hr />
              <Row className="card-body">
                <Col>
                  Generer nye vagtplaner frem til &nbsp;
                  <DateTimePicker value={endDate} onChange={date => setEndDate(date)} clearIcon={null} />
                  <span className='notify'><br/>{generateResult}</span>
                </Col>  
                <Col sm className="last ">
                  {
                    disableGenerateBtn && (
                      <div className="spinner-border text-dark center spinnerOuter" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )
                  } 
                  <Button variant="primary" disabled={disableGenerateBtn} onClick={()=>{generateNewPlans()}}>Generer nye planer</Button>
                </Col>        
              </Row>
              <hr />
              <Row className="card-body">
                <Col>
                  Opdater alle vagtplaner fra og med den &nbsp;
                  <DateTimePicker value={updateAfterDate} onChange={date => setUpdateAfterDate(date)} clearIcon={null} />
                  <br/>
                  erstat 
                  <select className="form-control short-guard" onChange={e => setUpdateFromGuard(e.currentTarget.value * 1)} value={updateFromGuard}>
                    <option value="">Vagt</option>
                    {
                      allGuards.map(
                        (d) =>
                          <option key={d.guardId} value={d.guardId}>{d.initial_name}</option>
                      )
                    }
                  </select>
                  med  
                  <select className="form-control short-guard" onChange={e => setUpdateToGuard(e.currentTarget.value * 1)} value={updateToGuard}>
                    <option value="">Vagt</option>
                    {
                      activeGuards.map(
                        (d) =>
                          <option key={d.guardId} value={d.guardId}>{d.initial_name}</option>
                      )
                    }
                  </select>
                  <span className='notify'><br/>{replaceResult}</span>
                </Col>  
                <Col sm className="last ">
                  {
                    disableReplaceBtn && (
                      <div className="spinner-border text-dark center spinnerOuter" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )
                  } 
                  <Button variant="primary" disabled={shouldDisableUpdate()} onClick={()=>{replaceInPlans()}}>Opdater og erstat</Button>
                </Col>        
              </Row>
            </>
          )
        }
      </Form>
    </>
  );

}
export default Settings;